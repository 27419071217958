import React from "react";

// Import typefaces
import "typeface-montserrat";
import "typeface-merriweather";

import profilePic from "./profile-pic.jpg";
import { rhythm } from "../utils/typography";

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          marginBottom: rhythm(2.5)
        }}
      >
        <img
          src={profilePic}
          alt={`Sam Delacruz`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(2),
            height: rhythm(2),
            borderRadius: "50%"
          }}
        />
        <p>
          Written by <a href="https://twitter.com/samdlc">Sam Delacruz</a>
          <br /> I'm a Software Engineer from London, building useful things.
        </p>
      </div>
    );
  }
}

export default Bio;
