import React from "react";
import { Link } from "gatsby";

import { rhythm, scale } from "../utils/typography";
import styles from "./Layout.module.css";

function Layout(props) {
  const { location, title, children } = props;
  const rootPath = `${__PATH_PREFIX__}/`;
  let header;

  const navigation = (
    <ul className={styles.navigation}>
      <li>
        <a href="https://twitter.com/samdlc" target="_blank">
          twitter
        </a>
      </li>
      <li>
        <a href="https://github.com/samdelacruz" target="_blank">
          github
        </a>
      </li>
      <li>
        <a href="https://linkedin.com/in/sjdelacruz" target="_blank">
          linkedin
        </a>
      </li>
    </ul>
  );

  if (location.pathname === rootPath) {
    header = (
      <h1
        className={styles.logo}
        style={{
          ...scale(1.2),
          marginBottom: rhythm(1.5),
          marginTop: 0
        }}
      >
        <Link
          style={{
            boxShadow: "none",
            textDecoration: "none",
            color: "inherit"
          }}
          to={"/"}
        >
          {title}
        </Link>
      </h1>
    );
  } else {
    header = (
      <h3
        className={styles.logo}
        style={{
          fontFamily: "Montserrat, sans-serif",
          marginTop: 0,
          marginBottom: rhythm(-1)
        }}
      >
        <Link
          style={{
            boxShadow: "none",
            textDecoration: "none",
            color: "inherit"
          }}
          to={"/"}
        >
          {title}
        </Link>
      </h3>
    );
  }
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`
      }}
    >
      {header}
      {children}
      {navigation}
    </div>
  );
}

export default Layout;
